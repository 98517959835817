<template>
    <div>
        <!-- Main Header-->
        <header class="main-header">

            <!-- Header Upper -->
            <div class="header-upper">
                <div class="auto-container">
                    <div class="inner-container clearfix">

                        <div class="pull-left logo-box">
                            <div class="logo">
                                <a href="javascript:void(0)"><img src="../../assets/images/logo.png" alt="" title=""></a>
                            </div>
                        </div>

                        <div class="nav-outer clearfix">
                            <!--Mobile Navigation Toggler-->
                            <div class="mobile-nav-toggler"><span class="icon flaticon-menu-2"></span></div>
                            <!-- Main Menu -->
                            <nav class="main-menu navbar-expand-md">
                                <div class="navbar-header">
                                    <!-- Toggle Button -->
                                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                        <span class="icon-bar"></span>
                                        <span class="icon-bar"></span>
                                        <span class="icon-bar"></span>
                                    </button>
                                </div>

                                <div class="navbar-collapse collapse clearfix" id="navbarSupportedContent">
                                    <ul class="navigation clearfix">
                                        <li :class="$route.name==='Home'?'current':''">
                                            <router-link :to="{name: 'Home'}">
                                                Home
                                            </router-link>
                                        </li>
                                        <li class="dropdown"><a href="javascript:void(0)">About</a>
                                            <ul>
                                                <li :class="$route.name==='About'?'current':''">
                                                    <router-link :to="{name: 'About'}">
                                                        About Us
                                                    </router-link>
                                                </li>
                                                <li :class="$route.name==='FAQ'?'current':''">
                                                    <router-link :to="{name: 'FAQ'}">
                                                        FAQ
                                                    </router-link>
                                                </li>
                                                <!--                                                <li><a href="javascript:void(0)">Terms & Condition</a></li>-->
                                                <li>
                                                    <router-link :to="{name: 'PrivacyPolicy'}">
                                                        Privacy Policy
                                                    </router-link>
                                                </li>
                                            </ul>
                                        </li>
                                        <li class="dropdown"><a href="javascript:void(0)">Investments</a>
                                            <ul>
                                                <li>
                                                    <router-link :to="{name: 'BitcoinInv'}">
                                                        Bitcoin
                                                    </router-link>
                                                </li>
                                                <li>
                                                    <router-link :to="{name: 'GoldInv'}">
                                                        Gold
                                                    </router-link>
                                                </li>
                                                <li>
                                                    <router-link :to="{name: 'EthereumInv'}">
                                                        Ethereum
                                                    </router-link>
                                                </li>
                                                <li>
                                                    <router-link :to="{name: 'RealEstate'}">
                                                        Real Estate
                                                    </router-link>
                                                </li>
                                            </ul>
                                        </li>
                                        <li class="">
                                            <router-link :to="{name: 'Legal'}">
                                                Legal
                                            </router-link>
                                        </li>
                                        <li>
                                            <router-link :to="{name: 'Contact'}">
                                                Contact us
                                            </router-link>
                                        </li>
                                        <template v-if="!isLoggedIn">
                                            <li><a :href="$router.resolve({name:'Login'}).href">Login</a></li>
                                            <li><a :href="$router.resolve({name:'Register'}).href">Register</a></li>
                                        </template>
                                        <li v-else>
                                            <a :href="$router.resolve({name:'Dashboard'}).href">Dashboard</a>
                                        </li>


                                    </ul>
                                </div>
                            </nav>

                            <!-- Main Menu End-->
                            <div class="outer-box clearfix">

                                <!-- Phone Btn -->
                                <div class="phone-box">
                                    <div class="box-inner">
                                        <a class="phone" href="tel:+18588585471">+1 (858)-858‑5471 <span class="icon flaticon-call-1"></span></a>
                                    </div>
                                </div>

                                <!-- Nav Btn -->
                                <!--                                <div class="nav-btn navSidebar-button"><span class="icon flaticon-menu"></span></div>-->

                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <!--End Header Upper-->

            <!-- Sticky Header  -->
            <div class="sticky-header">
                <div class="auto-container clearfix">
                    <!--Logo-->
                    <div class="logo pull-left">
                        <a href="javascript:void(0)" title=""><img src="../../assets/images/logo.png" alt="" title=""></a>
                    </div>
                    <!--Right Col-->
                    <div class="pull-right">
                        <!-- Main Menu -->
                        <nav class="main-menu">
                            <!--Keep This Empty / Menu will come through Javascript-->
                        </nav><!-- Main Menu End-->

                        <!-- Main Menu End-->
                        <div class="outer-box clearfix">
                            <!-- Nav Btn -->
                            <div class="mobile-nav-toggler be">
                                <span class="icon flaticon-menu"></span>
                            </div>

                        </div>

                    </div>
                </div>
            </div><!-- End Sticky Menu -->

            <!-- Mobile Menu  -->
            <div class="mobile-menu">
                <div class="menu-backdrop"></div>
                <div class="close-btn"><span class="icon flaticon-multiply"></span></div>

                <nav class="menu-box">
                    <div class="nav-logo"><a href="javascript:void(0)"><img src="../../assets/images/logo.png" alt="" title=""></a></div>
                    <div class="menu-outer"><!--Here Menu Will Come Automatically Via Javascript / Same Menu as in Header--></div>
                </nav>
            </div><!-- End Mobile Menu -->

        </header>
        <!-- End Main Header -->

    </div>
</template>

<script>
import {mapGetters} from "vuex"

export default {
    name: "topNav",
    computed: {
        ...mapGetters('auth', ['isLoggedIn']),
    },
    mounted() {
        // setTimeout(()=>{
        //     //Mobile Nav Hide Show
        //     if($('.mobile-menu').length){
        //
        //         $('.mobile-menu .menu-box').mCustomScrollbar();
        //
        //         var mobileMenuContent = $('.main-header .nav-outer .main-menu').html();
        //         $('.mobile-menu .menu-box .menu-outer').append(mobileMenuContent);
        //         $('.sticky-header .main-menu').append(mobileMenuContent);
        //
        //         //Dropdown Button
        //         $('.mobile-menu li.dropdown .dropdown-btn').on('click', function() {
        //             $(this).toggleClass('open');
        //             $(this).prev('ul').slideToggle(500);
        //         });
        //
        //
        //         //Dropdown Button
        //         $('.mobile-menu li.dropdown .dropdown-btn').on('click', function() {
        //             $(this).toggleClass('open');
        //             $(this).prev('.mega-menu').slideToggle(500);
        //         });
        //
        //         //Menu Toggle Btn
        //         $('.mobile-nav-toggler').on('click', function() {
        //             $('body').addClass('mobile-menu-visible');
        //         });
        //         //
        //         //Menu Toggle Btn
        //         $('.mobile-menu .menu-backdrop,.mobile-menu .close-btn').on('click', function() {
        //             $('body').removeClass('mobile-menu-visible');
        //         });
        //
        //     }
        // }, 2000)
    }
}
</script>

<style scoped>
    @media screen and (min-width: 320px) {
        .be{
            display: block;
        }
    }
    @media screen and (min-width: 768px) {
        .be{
            display: none;
        }
    }
</style>