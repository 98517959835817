<template>
    <div>
        <div class="page-wrapper">

            <!-- Preloader -->
            <div class="preloader"></div>

            <top-nav/>

            <router-view/>

           <foot-nav/>

        </div>

        <div class="back-to-top scroll-to-target show-back-to-top" data-target="html">TOP</div>
    </div>
</template>

<script>
import topNav from "../../components/home/topNav";
import footNav from "../../components/home/footNav";

export default {
    name: "Container",
    data(){
        return {

        }
    },
    components: {
        topNav,
        footNav
    },
    mounted(){
        const home_scripts = require('../../assets/js/custom-home');
        home_scripts();
        if($('.preloader').length){
            $('.preloader').delay(200).fadeOut(500);
        }
    }
}
</script>

<style scoped>

</style>