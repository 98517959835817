module.exports = function(){
    return $(function(){
        $('body').append(`
        <script src="/assets/js/jquery.fancybox.js"></script>
        <script src="/assets/js/appear.js"></script>
        <script src="/assets/js/parallax.min.js"></script>
        <script src="/assets/js/tilt.jquery.min.js"></script>
        <script src="/assets/js/jquery.paroller.min.js"></script>
        <script src="/assets/js/owl.js"></script>
        <script src="/assets/js/wow.js"></script>
        <script src="/assets/js/nav-tool.js"></script>
        <script src="/assets/js/jquery-ui.js"></script>
        <script src="/assets/js/jquery.mCustomScrollbar.concat.min.js"></script>
        <script src="/assets/js/script.js"></script>
        `)
    })
}